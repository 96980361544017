<template>
<div>
  <b-row>
    <b-col cols="6" v-for="(point, index) in pointsConfig" :key="point.configType">
      <point-card :numeric="point.configType==='AMOUNT'" v-model="pointsConfig[index]"></point-card>
    </b-col>
  </b-row>
</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import PointCard from '@/components/partial/config/pointCard'

export default {
  name: 'SuitPointView',
  components: { PointCard },
  data:()=>({pointsConfig:[]}),
  created() {
    this.refresh();
  },
  computed:{
    ...mapGetters('suitPointConfig', ['pointTypes'])
  },
  methods: {
    ...mapActions('suitPointConfig', ['getSuitPointConfig']),
    async refresh() {
      let data = await this.getSuitPointConfig();
      let dataMap = data.map(item=> item.configType);
      this.pointTypes.forEach(type => {
        if(!dataMap.includes(type)) {
          data.push({configType: type, values: {}});
        }
      });
      //Sort data by pointTypes
      data.sort((a,b) => this.pointTypes.indexOf(a.configType) - this.pointTypes.indexOf(b.configType));
      this.pointsConfig = data;


    }
  }
}
</script>

<style scoped>

</style>
