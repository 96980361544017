<template>
<div class="point-config-card">
  <b-card no-body>
    <b-card-header class="p-1 d-flex">
      <div>
        <h3>{{$t(`config.points.${pointConfig.configType}`)}}</h3>
      </div>
      <div style="letter-spacing: 1rem;">
        <b-link variant="outline-primary" size="sm" class="ml-auto" @click="loadPointConfig" v-b-tooltip :title="$t('operations.refresh')">
          <i class="fa fa-refresh"></i>
        </b-link>
        <b-link variant="outline-primary" size="sm" class="ml-auto" @click="addRow" v-b-tooltip :title="$t('operations.add')">
          <i class="fa fa-plus"></i>
        </b-link>
        <b-link size="sm" class="text-warning ml-auto" @click="save" v-b-tooltip :title="$t('operations.save')">
          <i class="fa-regular fa-floppy-disk"></i>
        </b-link>
      </div>
    </b-card-header>
    <b-table-simple class="m-0" hover small caption-top responsive>
      <b-thead head-variant="light">
        <b-tr>
          <b-th style="width: 50%">
            <span v-if="numeric !== true">{{ $t('words') }}</span>
            <span v-else>{{ $t('comparing.greaterThan') }}</span>
          </b-th>
          <b-th style="width: 15%">{{$t('columns.excluded')}}</b-th>
          <b-th style="width: 20%">{{$t('columns.points')}}</b-th>
          <b-th style="width: 15%"></b-th>
        </b-tr>
      </b-thead>
    </b-table-simple>
    <perfect-scrollbar class="position-relative" style="height: 300px">
      <b-table-simple class="m-0" hover small caption-top responsive>
      <b-tbody>
        <b-tr v-bind:class="{'row-edited':item.edited, 'row-deleted': item.deleted}" v-for="(item,index) in pointConfigValues" :key="index">
          <!--key-->
          <b-td style="width: 50%" v-if="item.editing">
            <b-input :number="numeric === true" size="sm" v-model="item.key" @change="toCamelCase(item)"/>
          </b-td>
          <b-td style="width: 50%" v-else>{{item.key}}</b-td>
          <!--blackListed-->
          <b-td class="text-center" style="width: 15%" v-if="item.editing">
            <b-form-checkbox  v-model="item.blackListed" />
          </b-td>
          <b-td class="text-center" style="width: 15%" v-else> <i v-if="item.blackListed" class="fa-solid fa-xmark"></i></b-td>
          <!--value-->
          <b-td style="width: 20%" v-if="item.editing">
            <b-input v-if="item.blackListed !== true" number size="sm" style="width: 100px" v-model="item.value" />
          </b-td>
          <b-td style="width: 20%" v-else><span v-if="item.blackListed !== true">{{item.value}}</span></b-td>
          <!--action-->
          <b-td v-if="item.editing" style="width: 15%;letter-spacing: 1rem;">
            <b-link variant="outline-primary" size="sm" class="ml-auto" @click="saveRow(index)" :title="$t('operations.save')">
              <i class="fa-solid fa-check"></i>
            </b-link>
            <!--Delete-->
            <b-link variant="outline-primary" size="sm" class="ml-auto" @click="deleteRow(index)" :title="$t('operations.delete')">
              <i class="fa-solid fa-trash"></i>
            </b-link>
            <b-link variant="outline-primary" size="sm" class="ml-auto" @click="cancelEditRow(index)" :title="$t('operations.cancel')">
              <i class="fa-solid fa-xmark"></i>
            </b-link>
          </b-td>
          <b-td style="width: 15%; text-align: right; padding-right: 3em" v-else>
            <b-link variant="outline-primary" size="sm" class="ml-auto" @click="editRow(index)" :title="$t('operations.edit')">
              <i class="fa-regular fa-pen-to-square"></i>
            </b-link>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </perfect-scrollbar>
    <div class="px-1 py-1">
      <b-form-checkbox v-model="pointConfig.defaultIgnore">
        {{ $t('config.ignoreDefault') }}
      </b-form-checkbox>
    </div>
  </b-card>

</div>
</template>

<script>
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  key: 'pointCard',
  data: () => ({
    pointConfig: {values:[], defaultIgnore: false, configType:''} ,
    pointConfigValues: [],
  }),
  props: ['value', 'numeric'],
  watch:{
    value: {
      handler: function (val, oldVal) {
        this.loadPointConfig();
      },
      immediate: true
    }
  },
  computed:{
    selectedPointConfig: {
      get() {
        return this.value
      },
      set(value) {
        this.value = value
      }
    },
    edited(){
      return this.pointConfigValues.filter(item => item.edited).length > 0;
    }
  },
  methods: {
    ...mapActions('suitPointConfig',['saveSuitPointConfig']),
    loadPointConfig() {
      let $this = this;
      this.pointConfig = {...$this.selectedPointConfig}
      this.pointConfigValues = (this.selectedPointConfig.values || []).map(item => {
        return {
          originalKey: $this.numeric !== true ? item.key : item.keyNumeric,
          key: $this.numeric !== true ? item.key : item.keyNumeric,
          value: item.value,
          blackListed: item.blackListed || false,
          editing: false,
          edited: false,
          deleted: false
        }
      });
    },
    toCamelCase(item){
      item.key = item.key.toLowerCase();
    },
    addRow(){
      this.pointConfigValues.push({ key: '', value: '', editing: true, edited: false });
    },
    editRow(index){
      let $this = this;
      this.pointConfigValues.forEach((item, i) => {
        if(item.editing) {
          $this.cancelEditRow(i)
        }
      });
      this.pointConfigValues[index].editing = true;
    },
    cancelEditRow(index){
      let originalKey = this.pointConfigValues[index].originalKey;
      if(originalKey == null) { // new line
        this.pointConfigValues.splice(index, 1);
        return;
      }
      let originalPoints = this.selectedPointConfig.values.find(item => this.numeric !== true ? item.key === originalKey : item.keyNumeric === originalKey);
      this.pointConfigValues[index].key = originalKey;
      this.pointConfigValues[index].value = originalPoints?.value;
      this.pointConfigValues[index].blackListed = originalPoints?.blackListed;
      this.pointConfigValues[index].editing = false;
    },
    saveRow(index){
      this.pointConfigValues[index].editing = false;
      this.pointConfigValues[index].edited = true;
    },
    deleteRow(index){
      this.pointConfigValues[index].deleted = true;
      this.pointConfigValues[index].editing = false;
      this.pointConfigValues[index].edited = true;
    },
    async save(){
      let $this = this;
      let newPointValues = this.pointConfigValues.filter(p => p.deleted !== true ).map(item => ({
        [$this.numeric !== true ? 'key' : 'keyNumeric']: item.key,
        value: item.blackListed !== true ? item.value : 0.0,
        blackListed: item.blackListed
      }));
      this.pointConfig.values = newPointValues;
      this.saveSuitPointConfig(this.pointConfig).then(
          ()=>{
            $this.$emit('input', $this.pointConfig);
            $this.loadPointConfig();
            $this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Submitted ',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          },
          err=>{
            $this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: err.error,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
      )


    }
  }


}
</script>

<style lang="scss">
.point-config-card{
  .row-edited{
    background-color: #ffe70026;
  }
  .row-deleted{
    background-color: rgba(255, 0, 8, 0.15);
  }
}
.ps-container > .ps-scrollbar-x-rail,
.ps-container > .ps-scrollbar-y-rail {   opacity: 0.6; }
</style>
